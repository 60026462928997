<style scoped>
.search {
    width: 30%;
}
@media only screen and (max-width: 600px) {
  .search {
    width: 100%;
  }
}

</style>>
<template>
    <div v-loading.fullscreen.lock="loading">
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" align="right">
            <el-button type="primary" @click="$router.push('/add-user');" size="mini" v-if="LoggedUser.permission == 1">Хэрэглэгч бүртгэх</el-button>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow border-0 p-5">
                        <div class="table-responsive mt-2">
                                <base-table class="table align-items-center table-flush thead-light"
                                            tbody-classes="list"
                                            :data="users">
                                <template slot="columns">
                                    <!-- <th :style="{'background': '#F6F9FC'}">#</th> -->
                                    <th :style="{'background': '#F6F9FC'}">Овог нэр</th>
                                    <th :style="{'background': '#F6F9FC'}">Зураг</th>
                                    <th :style="{'background': '#F6F9FC'}">ИМэйл</th>
                                    <th :style="{'background': '#F6F9FC'}">Утасны дугаар</th>
                                    <th :style="{'background': '#F6F9FC'}">Хот / Аймаг</th>
                                    <th :style="{'background': '#F6F9FC'}">Дүүрэг / Сум</th>
                                    <th :style="{'background': '#F6F9FC'}">Эрх</th>
                                    <th :style="{'background': '#F6F9FC'}" v-if="LoggedUser.permission == 1"></th>
                                </template>
                                <template slot-scope="{row}">
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm" style="color: #8898aa;">{{row.lastname}} {{row.firstname}}</span><br>                                                
                                            </div>
                                        </div>
                                    </th>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <el-avatar size="small" :src="'/contents/image/user/' + row.image"></el-avatar>
                                                <!-- <span class="name mb-0 text-sm">{{row.image}}</span> -->
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.email}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.phone}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.cityname}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.districtname}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm" v-if="row.permission == 1">Админ</span>
                                                <span class="name mb-0 text-sm" v-else-if="row.permission == 2">Оператор</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td align="right" v-if="LoggedUser.permission == 1">
                                        <el-tooltip class="item" effect="dark" content="Устгах" placement="top">
                                            <base-button type="danger" @click="deleteUser(row.id)" size="sm"><span class="ni ni-fat-remove"></span></base-button>
                                        </el-tooltip>
                                    </td>
                                </template>
                            </base-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <modal :show.sync="modals.userChangeRole">
            <h6 slot="header" class="modal-title" id="modal-title-default">Хэрэглэгчид эрх олгох</h6>
              <div class="row">
                <div class="col-md-12">
                    <el-select v-model="role.val" placeholder="Сонгоно уу" style="width: 100%">
                        <el-option
                        v-for="item in role.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>

                </div>
              </div>

              <template slot="footer">
                <base-button type="primary" @click="usertoemp">Хадгалах</base-button>
                <base-button type="link" class="ml-auto" @click="modals.userChangeRole = false">Гарах</base-button>
          </template>
        </modal> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            users: [],
            loading: false,
            LoggedUser: []
        }
    },
    mounted() {
        this.LoggedUser = JSON.parse(localStorage.getItem('user'));
        this.getUsers();
    },
    methods: {
        deleteUser(id) {
            this.$confirm('Устгахдаа итгэлтэй байна уу?', 'Санамж', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {

                if(id == this.LoggedUser.id) {
                    this.$message({
                        message: 'Уучлаарай, Өөрийгөө устгах боломжгүй',
                        type: 'error'
                    });
                } else {
                    var rts = this;
                    this.loading = true;
                    var token = localStorage.getItem('token');
                    rts.$axios({
                        method: 'POST',
                        url: this.$appUrl + '/user/delete-user',
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        data: {
                            id
                        }
                    }).then(data => {
                        rts.loading = false;
                        if(data.data.result == 'success') {
                            rts.users.forEach((el, index) => {
                                if(id == el.id) {
                                    rts.users.splice(index, 1);
                                }
                            });
                        }
                    }).catch(error => {
                        rts.loading = false;
                        console.log(error);
                    });
                }
            });
        },
        getUsers() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/get-users',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(data => {
                rts.loading = false;
                rts.users = data.data.users;
            }).catch(error => {
                rts.loading = false;
                console.log(error);
            });
        }
    }
}
</script>